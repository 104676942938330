import React, { useMemo, useState, useEffect } from 'react'
import { Pack } from '../../../types/packs'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Divider,
  HStack,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Spinner,
  Spinner as SpinnerIcon,
} from '@chakra-ui/react'
import { AnyEventObject, StateFrom } from 'xstate'
import { PackTransaction } from '../types'
import TransactionTable from './TransactionTable'

import useSolana from '../../../hooks/useSolana'
import { getJupPackTransaction } from '../../../utils/jupiter'
import { useAppContext } from '../../../../../contexts/appContext'

type RightPanelProps = {
  state: StateFrom<any>
  pack?: Pack
  uiPrice?: string
  transactions: PackTransaction[]
  previousTxs: PackTransaction[]
  send: (event: AnyEventObject) => void
}

type PackPanelProps = {
  state: StateFrom<any>
  pack?: Pack
  uiPrice?: string
  transactions: PackTransaction[]
  previousTxs: PackTransaction[]
}

type ExchangePanelProps = {
  state: StateFrom<any>
  send: (event: AnyEventObject) => void
}

const SOL_LAMPORTS = 10 ** 9
const USDC_LAMPORTS = 10 ** 6
const ATLAS_LAMPORTS = 10 ** 8

const SOL_MINT = 'So11111111111111111111111111111111111111112'
const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
const ATLAS_MINT = 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx'

const getUserBalance = (balances: any) => {
  if (balances.length == 0) return null

  const usdc = balances.find((balance: any) => balance.token.symbol === 'USDC')
  const sol = balances.find((balance: any) => balance.token.symbol === 'SOL')
  const atlas = balances.find((balance: any) => balance.token.symbol === 'ATLAS')
  return {
    usdc: usdc?.amount / USDC_LAMPORTS || 0,
    sol: sol?.amount / SOL_LAMPORTS || 0,
    atlas: atlas?.amount / ATLAS_LAMPORTS || 0,
  }
}

const getPackPrice = (packDetails: any) => {
  if (!packDetails) return { usdc: 0, sol: 0, atlas: 0 }

  const usdc = packDetails.price.find((price: any) => price.token.symbol === 'USDC')
  const sol = packDetails.price.find((price: any) => price.token.symbol === 'SOL')
  const atlas = packDetails.price.find((price: any) => price.token.symbol === 'ATLAS')

  return {
    usdc: usdc?.amount / USDC_LAMPORTS || 0,
    sol: sol?.amount / SOL_LAMPORTS || 0,
    atlas: atlas?.amount / ATLAS_LAMPORTS || 0,
  }
}

const PackPanel: React.FC<PackPanelProps> = ({
  state,
  pack,
  uiPrice,
  transactions,
  previousTxs,
}) => {
  const isLoadingTransactions = state.matches('fetchTxs')

  return (
    <VStack width={'100%'}>
      <HStack justifyContent="space-between" width={'100%'} marginTop={'10px'}>
        <Text className="buying-modal-price-title">Pack:</Text>
        <Text className="buying-modal-price-title" style={{ fontStyle: 'italic' }}>
          {pack?.name}
        </Text>
      </HStack>
      <Divider />
      <HStack justifyContent="space-between" width={'100%'}>
        <Text className="buying-modal-price-title">Price:</Text>
        <Text className="buying-modal-price-value">{uiPrice}</Text>
      </HStack>
      <Accordion width={'100%'} allowToggle defaultIndex={1}>
        <AccordionItem>
          <h2>
            <AccordionButton style={{ paddingLeft: '0px' }}>
              <Box as="span" flex="1" textAlign="left">
                Description
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{pack?.description}</AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton style={{ paddingLeft: '0px' }}>
              <Box as="span" flex="1" textAlign="left">
                Transactions
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <TransactionTable
              isLoadingTransactions={isLoadingTransactions}
              transactions={transactions}
              previousTxs={previousTxs}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  )
}

const getSolToUsdcRate = async () => {
  try {
    const res = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
    )
    const data = await res.json()
    return data.solana.usd
  } catch (e) {
    console.log('ERROR GETTING SOL TO USDC RATE', e)
  }
}

const getAtlasToUsdcRate = async () => {
  try {
    const res = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=atlas&vs_currencies=usd'
    )
    const data = await res.json()
    return data.atlas.usd
  } catch (e) {
    console.log('ERROR GETTING SOL TO USDC RATE', e)
  }
}

const ExchangePanel: React.FC<ExchangePanelProps> = ({ state, send }) => {
  const { uid } = useAppContext()
  const { signAndSendTransactions } = useSolana()

  const [solToUsdcRate, setSolToUsdcRate] = useState<number | null>(null)
  const [atlasToUsdcRate, setAtlasToUsdcRate] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isExchanging, setIsExchanging] = useState(false)
  const [exchangeComplete, setExchangeComplete] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  useEffect(() => {
    const fetchRate = async () => {
      try {
        const [solToUsdcRate, atlasToUsdcRate] = await Promise.all([
          getSolToUsdcRate(),
          getAtlasToUsdcRate(),
        ])
        setSolToUsdcRate(solToUsdcRate)
        setAtlasToUsdcRate(atlasToUsdcRate)
      } catch (error) {
        console.error('Failed to fetch SOL to USDC rate:', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchRate()
  }, [getSolToUsdcRate])

  const getUserBalance = () => {
    if (!state.context.balances) return { usdc: 0, sol: 0, atlas: 0 }

    const usdc = state.context.balances.find((balance: any) => balance.token.symbol === 'USDC')
    const sol = state.context.balances.find((balance: any) => balance.token.symbol === 'SOL')
    const atlas = state.context.balances.find((balance: any) => balance.token.symbol === 'ATLAS')

    return {
      usdc: usdc?.amount / USDC_LAMPORTS || 0,
      sol: sol?.amount / SOL_LAMPORTS || 0,
      atlas: atlas?.amount / ATLAS_LAMPORTS || 0,
    }
  }

  const getPackPrice = () => {
    if (!state.context.packDetails) return { usdc: 0, sol: 0, atlas: 0 }

    const usdc = state.context.packDetails.price.find((price: any) => price.token.symbol === 'USDC')
    const sol = state.context.packDetails.price.find((price: any) => price.token.symbol === 'SOL')
    const atlas = state.context.packDetails.price.find(
      (price: any) => price.token.symbol === 'ATLAS'
    )

    return {
      usdc: usdc?.amount / USDC_LAMPORTS || 0,
      sol: sol?.amount / SOL_LAMPORTS || 0,
      atlas: atlas?.amount / ATLAS_LAMPORTS || 0,
    }
  }

  const userBalance = useMemo(() => getUserBalance(), [state.context.balances])
  const packPrice = useMemo(() => getPackPrice(), [state.context.packDetails])
  const exchangeValues = useMemo(() => {
    return {
      usdc: Math.max(0, 1.2 * (packPrice.usdc - userBalance.usdc)), // adding some margin for price changes
      sol: Math.max(0, 1.2 * (packPrice.sol - userBalance.sol)),
      atlas: Math.max(0, 1.2 * (packPrice.atlas - userBalance.atlas)),
    }
  }, [packPrice, userBalance])

  const handleExchange = async (fromToken: 'SOL' | 'USDC') => {
    setIsExchanging(true)
    const userPublicKey = uid?.split('-')[1] as string

    // Simplified quote data generation
    const quoteData = generateQuoteData(fromToken)

    if (quoteData.length > 0) {
      try {
        const pTxs = await getJupPackTransaction(quoteData, userPublicKey)
        await signAndSendTransactions(pTxs)
        setExchangeComplete(true)
        send({ type: 'UPDATE_BALANCES' })
      } catch (e) {
        console.error('Transaction failed:', e)
      }
    }

    setIsExchanging(false)
  }

  // Helper function to generate quote data
  const generateQuoteData = (fromToken: 'SOL' | 'USDC') => {
    const mintFrom = fromToken === 'SOL' ? SOL_MINT : USDC_MINT
    const exchanges = [
      { needed: exchangeValues.atlas, mint: ATLAS_MINT, symbol: 'ATLAS' },
      { needed: exchangeValues.usdc, mint: USDC_MINT, symbol: 'USDC' },
      { needed: exchangeValues.sol, mint: SOL_MINT, symbol: 'SOL' },
    ]

    return exchanges
      .filter(({ needed }) => needed > 0)
      .map(({ needed, mint, symbol }) => ({
        from: mintFrom,
        to: mint,
        amount: Math.ceil(
          needed *
          (symbol === 'ATLAS' ? ATLAS_LAMPORTS : symbol === 'USDC' ? USDC_LAMPORTS : SOL_LAMPORTS)
        ),
        description: `Exchange ${fromToken} for ${symbol}`,
      }))
  }

  const handleRefreshBalance = async () => {
    setIsRefreshing(true)
    send({ type: 'UPDATE_BALANCES' })
    setIsRefreshing(false)
  }

  if (isLoading) {
    return (
      <VStack width="100%" justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
        <Text>Loading exchange rate...</Text>
      </VStack>
    )
  }

  const renderExchangeOption = () => {
    const { usdc: usdcNeeded, sol: solNeeded, atlas: atlasNeeded } = exchangeValues

    if (usdcNeeded <= 0 && solNeeded <= 0 && atlasNeeded <= 0) {
      return <Text textAlign="left">You have sufficient funds to purchase the pack.</Text>
    }

    if (usdcNeeded > 0 && solNeeded > 0) {
      return (
        <Text textAlign="left" color="red.500">
          Insufficient funds. Please add more SOL or USDC to your wallet.
        </Text>
      )
    }

    const canExchangeFromSol =
      (userBalance.sol * solToUsdcRate! >= usdcNeeded + atlasNeeded * atlasToUsdcRate!) && solNeeded === 0

    const canExchangeFromUsdc =
      (userBalance.usdc >= solNeeded * solToUsdcRate! + atlasNeeded * atlasToUsdcRate!) && usdcNeeded === 0

    if (!canExchangeFromSol && !canExchangeFromUsdc) {
      return (
        <Text textAlign="left" color="red.500">
          Insufficient funds. Please add more SOL or USDC to your wallet.
        </Text>
      )
    }

    const exchangeConfig = getExchangeConfig(usdcNeeded, solNeeded, atlasNeeded, canExchangeFromSol)

    return (
      <>
        <Text textAlign="left">{exchangeConfig.info}</Text>
        <Button
          onClick={() => handleExchange(exchangeConfig.currency)}
          isLoading={isExchanging}
          loadingText="Exchanging..."
          isDisabled={isExchanging || exchangeComplete}
        >
          {exchangeComplete ? 'Exchange Complete' : exchangeConfig.buttonText}
        </Button>
      </>
    )
  }

  const getExchangeConfig = (
    usdcNeeded: number,
    solNeeded: number,
    atlasNeeded: number,
    canExchangeFromSol: boolean
  ) => {
    const configs = [
      {
        condition: usdcNeeded > 0 && atlasNeeded > 0,
        info: `You need ${exchangeValues.usdc.toFixed(2)} USDC and ${exchangeValues.atlas.toFixed(
          2
        )} ATLAS more to proceed. Exchange ${(
          exchangeValues.usdc / solToUsdcRate! +
          exchangeValues.atlas * atlasToUsdcRate!
        ).toFixed(4)} SOL to proceed with the pack.`,
        buttonText: 'Exchange SOL for USDC and ATLAS',
        currency: 'SOL' as const,
      },
      {
        condition: solNeeded > 0 && atlasNeeded > 0,
        info: `You need ${exchangeValues.sol.toFixed(2)} SOL and ${exchangeValues.atlas.toFixed(
          2
        )} ATLAS more to proceed. Exchange ${(
          exchangeValues.sol * solToUsdcRate! +
          exchangeValues.atlas * atlasToUsdcRate!
        ).toFixed(4)} USDC to proceed with the pack.`,
        buttonText: 'Exchange USDC for SOL and ATLAS',
        currency: 'USDC' as const,
      },
      {
        condition: solNeeded > 0,
        info: `You need ${exchangeValues.sol.toFixed(2)} SOL more to proceed. Exchange ${(
          exchangeValues.sol * solToUsdcRate!
        ).toFixed(4)} USDC to proceed with the pack.`,
        buttonText: 'Exchange USDC for SOL',
        currency: 'USDC' as const,
      },
      {
        condition: usdcNeeded > 0,
        info: `You need ${exchangeValues.usdc.toFixed(2)} USDC more to proceed. Exchange ${(
          exchangeValues.usdc / solToUsdcRate!
        ).toFixed(4)} SOL to proceed with the pack.`,
        buttonText: 'Exchange SOL for USDC',
        currency: 'SOL' as const,
      },
      {
        condition: atlasNeeded > 0,
        info: `You need ${exchangeValues.atlas.toFixed(2)} ATLAS more to proceed. Exchange ${(
          canExchangeFromSol
            ? (exchangeValues.atlas * atlasToUsdcRate! / solToUsdcRate!).toFixed(4)
            : (exchangeValues.atlas * atlasToUsdcRate!).toFixed(4)
        )} ${canExchangeFromSol ? 'SOL' : 'USDC'} to proceed with the pack.`,
        buttonText: `Exchange ${canExchangeFromSol ? 'SOL' : 'USDC'} for ATLAS`,
        currency: canExchangeFromSol ? ('SOL' as const) : ('USDC' as const),
      },
    ]

    return configs.find((config) => config.condition) || configs[0]
  }

  return (
    <VStack width={'100%'} spacing={4}>
      <HStack justifyContent="space-between" width={'100%'}>
        <Text className="buying-modal-price-title"> Pack Price:</Text>
        <Text className="buying-modal-price-value">
          {[
            packPrice.usdc > 0 && `${packPrice.usdc.toFixed(2)} USDC`,
            packPrice.sol > 0 && `${packPrice.sol.toFixed(2)} SOL`,
            packPrice.atlas > 0 && `${packPrice.atlas.toFixed(2)} ATLAS`,
          ]
            .filter(Boolean)
            .join(' + ')}
        </Text>
      </HStack>
      <Divider />
      <HStack justifyContent="space-between" width={'100%'}>
        <Text className="buying-modal-price-title">Your Balance:</Text>
        <Text className="buying-modal-price-value">
          {[
            userBalance.usdc > 0 && packPrice.usdc > 0 && `${userBalance.usdc.toFixed(2)} USDC`,
            userBalance.sol > 0 && packPrice.sol > 0 && `${userBalance.sol.toFixed(2)} SOL`,
            userBalance.atlas > 0 && packPrice.atlas > 0 && `${userBalance.atlas.toFixed(2)} ATLAS`,
          ]
            .filter(Boolean)
            .join(' + ')}
        </Text>
      </HStack>

      {renderExchangeOption()}
      <Button
        onClick={handleRefreshBalance}
        size="sm"
        isLoading={isRefreshing}
        loadingText="Refreshing"
        spinner={<SpinnerIcon size="sm" />}
      >
        Refresh balance
      </Button>
    </VStack>
  )
}

const RightPanel: React.FC<RightPanelProps> = ({
  state,
  pack,
  uiPrice,
  transactions,
  previousTxs,
  send,
}) => {
  const userBalance = useMemo(
    () => getUserBalance(state.context.balances),
    [state.context.balances]
  )
  const packPrice = useMemo(
    () => getPackPrice(state.context.packDetails),
    [state.context.packDetails]
  )

  const hasEnoughFunds = useMemo(() => {
    if (!userBalance) return true
    return (
      userBalance.usdc >= packPrice.usdc &&
      userBalance.sol >= packPrice.sol &&
      userBalance.atlas >= packPrice?.atlas
    )
  }, [userBalance, packPrice])

  if (!userBalance || !packPrice) {
    return (
      <VStack
        spacing={0}
        ml={{ md: 6 }}
        width={'100%'}
        className="buying-modal-right"
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        height="200px"
      >
        <Spinner size="xl" />
      </VStack>
    )
  }

  return (
    <VStack
      spacing={0}
      ml={{ md: 6 }}
      width={'100%'}
      className="buying-modal-right"
      flexShrink={0}
      alignItems="center"
      justifyContent="space-between"
    >
      {hasEnoughFunds ? (
        <PackPanel
          state={state}
          pack={pack}
          uiPrice={uiPrice}
          transactions={transactions}
          previousTxs={previousTxs}
        />
      ) : (
        <Tabs width="100%" variant="enclosed">
          <TabList>
            <Tab>Pack</Tab>
            <Tab isDisabled={hasEnoughFunds} className="buying-modal-alert-icon">
              Exchange
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <PackPanel
                state={state}
                pack={pack}
                uiPrice={uiPrice}
                transactions={transactions}
                previousTxs={previousTxs}
              />
            </TabPanel>

            <TabPanel>
              <ExchangePanel state={state} send={send} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      <Alert status="info" className="buying-modal-alert" borderRadius={'4px'} width="full">
        <AlertIcon className="buying-modal-alert-icon" />
        <AlertDescription className="buying-modal-alert-description">
          Purchase price includes 5% Tulle convenience fee.
        </AlertDescription>
      </Alert>
    </VStack>
  )
}

export default RightPanel
